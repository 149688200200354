import React from "react"
import { useEffect } from "react"
import "./ServiceTemplate.css"
import { Link } from "react-router-dom"
import AOS from "aos"
import "aos/dist/aos.css";
const ServiceTemplate = ({ headerSection, timelineItems, icons, title, featureimg, coreServices, tagline, features, streamlinedesc, }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="bg-contain">
        <div className="container-fluid ">
          {headerSection.map((header, index) => (
            <div className="row pt-5 pb-5">
              <div key={index} className="col-lg-6 d-flex flex-column justify-content-center service-header p-3 ps-4">
                <h2 className="custom-font">{header.heading}</h2>
                <h6>{header.title}</h6>
                <p>{header.text}</p>
                <Link to={"/contact"}> <button className="collaborate-btn"><span>Let's Collaborate</span></button> </Link>
              </div>
              <div className="col-lg-6 d-flex justify-content-center pt-5 pb-5 ">
                <img src={`/${header.image}`} alt={header.title} width={400} className="img-fluid p-3 pe-0"></img>
              </div>
            </div>
          ))}
          <div className="heading-streamline  text-center p-2 " data-aos="fade-up">
            <h1 className="custom-font pb-3 ">Our Streamlined {title} process </h1>
            <h6><span className="fw-bolder me-2 " style={{ color: "#052f3f" }}>Our Process:</span>{tagline}</h6>
            <p>{streamlinedesc}</p>
          </div>
        </div>
        <div className="timeline-container p-5 mt-5 mb-5">
          {timelineItems.map((item, index) => (
            <div
              key={index}
              className={`timeline-item custom-font ${index % 2 === 0 ? 'left' : 'right'}`}
            >
              <div className="content" data-aos="flip-up">
                <h3>{item.title}</h3>
              </div>

            </div>
          ))}
        </div>
        <div className="heading-streamline text-center p-2 pt-5" data-aos="fade-up">
          <h1 className="custom-font ">Technology Stack</h1>
          <h6>Harnessing the Power of the <span className="fw-bolder  " style={{ color: "#052f3f" }}>Latest Tools</span></h6>
        </div>
        <div className="container outer-tech">
          <div className="container inner-tech d-flex flex-row align-items-center justify-content-evenly">
            {icons.map((icon, index) => (
              <div key={index} className="icon-container">
                <img src={`/${icon.imag}`} className="icon-img" />
              </div>
            ))}
          </div>
        </div>
        <div className="heading-streamline text-center p-2 pt-5" data-aos="fade-up">
          <h1 className="custom-font ">Why Choose Us?</h1>
          <h6>Your Trusted<span className="fw-bolder ms-2 " style={{ color: "#052f3f" }}>Partner</span></h6>
        </div>
        <div className="d-flex align-items-center justify-content-around  flex-column flex-lg-row ">
          <div className="feature-container  ">
            {features.map((feature, index) => (
              <div className="feature-item" key={index} data-aos="fade-right">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
          <div className="image-wrapper  " data-aos="fade-up">
            <img src={`/${featureimg}`} alt="" width={300} />
          </div>
        </div>
        <div className="heading-streamline text-center p-2 pt-5" data-aos="fade-up">
          <h1 className="custom-font ">Core Services</h1>
          <h6>What We <span className="fw-bolder  " style={{ color: "#052f3f" }}>Do?</span></h6>
        </div>
        <section className="timeline-services">
          {coreServices.map((services, index) => (
            <div className={`timeline-item-services ${index % 2 === 0 ? 'left-side' : 'right-side'}`} key={index}>
              <div className="content-services" data-aos="fade-up">
                <h3 className="custom-font">{services.title}</h3>
                <p>{services.description}</p>
              </div>
              <div className="image-container-services" data-aos="flip-down">
                <img src={`/${services.image}`} alt={services.title} />
              </div>
            </div>
          ))}
        </section>

        <div className="heading-streamline d-flex flex-column align-items-center justify-content-center  pt-5 pb-5  " data-aos="fade-up">
          <h1 className="custom-font mb-0">Innovation at the Core</h1>
          <h6>Driving Excellence in <span className="fw-bolder" style={{ color: "#052f3f" }}>Every Project</span></h6>
        </div>

        <div className="container innovation-container text-center text-white mt-0 mb-0  ">
          <h3 className="fw-bold ">Innovation is not just a buzzword at Neksogix</h3>
          <p >It's our way of life. We continuously push the boundaries of technology to deliver solutions that are not only innovative but also practical and scalable, helping you stay ahead of the competition.</p>
        </div>
        <div className="heading-streamline text-center p-5 pt-5" data-aos="fade-up">
          <h1 className="custom-font mt-3 ">Explore More About Projects</h1>
          <h6>Let's Build Something Amazing <span className="fw-bolder  " style={{ color: "#052f3f" }}>Together</span></h6>
          <Link to={{
            pathname: "/project",
            state: { selectedCategory: title }
          }}> <button className="collaborate-btn mb-5" style={{ width: 200 }}><span >View Projects</span></button></Link>
        </div>
      </div>
    </>
  )
}
export default ServiceTemplate