import React from 'react';

const AdminPage = () => {
    return (
        <div>
            <h1>Welcome to the Admin Page</h1>
            {/* Your admin content here */}
        </div>
    );
};

export default AdminPage;
