import React from 'react';
import blogpng from "../assets/Company.gif";
import { Link} from "react-router-dom";
import ouredge from "../assets/ouredge.svg";
import vision from "../assets/vision.svg";
import innovation from "../assets/Innovation.svg";
import perfection from "../assets/Perfection.svg";
import expert from "../assets/expertTeam.svg"
import './Company.css';
import ScheduleFooter from '../ScheduleFooter/ScheduleFooter';

const edgeValues = [
    {
        title: "Your Vision, Our Mission",
        description: "Your goals are our mission. We don't just offer services; we partner with you, aligning our expertise with your vision. Committed to innovation, we deliver tailored solutions that exceed expectations and drive long-term growth and sustainability.",
         image: vision
    },
    {
        title: "Pursuit of Perfection",
        description: "Perfection is our mantra. Every project is executed with precision, ensuring quality and reliability. Through rigorous quality checks and thorough validation, we ensure each stage, from start to launch, meets the highest standards.",
    image: perfection
},
    {
        title: "Tailored Innovation",
        description: "We don’t believe in one-size-fits-all solutions. Our team specializes in understanding your unique challenges and goals, creating custom software that aligns with your vision. Leveraging cutting-edge technology, we deliver innovative, tailored solutions that meet your needs perfectly.",
    image: innovation },
    {
        title: "Expert Team",
        description: "Our team is the cornerstone of our success, with extensive expertise in software development, data engineering, and UI/UX design. We invest in our people, providing them with the latest tools and training to stay ahead of trends and deliver cutting-edge solutions.",
    image: expert}
];

const teamMembers = [
    { id: 1, name: 'Awais Naeem',title:'Founder', position: 'Chief Executive Officer (CEO)', imgSrc: require('../assets/AwaisNaeem.png') },
    { id: 2, name: 'Adeel Naeem',title:'Co-Founder', position: 'Chief Technology Officer (CTO)', imgSrc: require('../assets/AdeelNaeem.png') },
];
const Company = () => {
    return (
        <>
            <div className="container-fluid bg-custom-section">
                <div className="row pt-lg-5 pb-lg-5 pt-0 pb-0 d-flex align-items-center ">
                    <div className="col-lg-6 col-12 main-content">
                        <h1>Coding the Future, Today at <span >Neksogix's </span></h1>
                        <div style={{ height: "15px"}}></div>
                        <Link to="/project"><button className="main-btn mt-4"><span>Explore Projects</span></button></Link>
                    </div>
                    <div className="col-12 col-lg-6 blogmain-img  " style={{width:640, paddingTop:40}}>
                        <img src={blogpng} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-white aboutsection">
                <div className="row pt-5 pb-5">
                    <div className="col-12 ps-5">
                        <h1 className='about-us'>About US</h1>
                        <hr className="about-underline" />
                    </div>
                    <div className="col-lg-6 col-12 p-5">
                        <h2 className="about-heading">
                            Transform Your Vision with<span style={{color:"#052f3f", fontSize:50 }}> Neksogix </span>Leading the Way in Technological Innovation
                        </h2>
                    </div>
                    <div className="col-lg-6 col-12 p-5">
                        <p className="about-paragraph ps-lg-5">
                            At <span style={{ color: "#00A79D", fontWeight:"bold", fontSize:32}}>Neksogix</span> , 
                            We're more than a software house - we're where innovation and technology meet. Our mission is to create solutions that drive growth, enhance efficiency, and ensure scalability. With dedication and expertise, we help businesses of all sizes navigate the digital landscape with confidence.
                        </p>
                    </div>
                </div>
            </div>

            <div className="core-values-section">
    <h1>Our Core Values</h1>
    <p className="core-values-description">
        We unleash the power of technology, designing innovative, bespoke solutions for our customers, all anchored by three core values.
    </p>

    <div className="row pt-5 pb-5 core-values-content">
        <div className="col-lg-4 col-md-4 col-sm-12 core-value-item">
            <h3>INTEGRITY</h3>
            <p>
                Integrity drives every decision we make. We maintain the highest ethical standards in all business practices.
            </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 core-value-item">
            <h3>QUALITY</h3>
            <p>
                Quality is our promise. We deliver excellence through attention to detail and constant, continuous improvement.
            </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 core-value-item">
            <h3>CUSTOMER FOCUS</h3>
            <p>
                Our customers come first. We listen, adapt, and always deliver solutions that align with their needs.
            </p>
        </div>
    </div>
</div>

           
<div className="container-fluid our-edge-section">
    <div className="d-flex align-items-center justify-content-center our-edge-title   flex-wrap">
        <img src={ouredge} alt="Our Edge" width={150} className="img-fluid mb-3 mb-md-0"/>
        <h4 className="our-edge">OUR EDGE</h4>
    </div>
    <div className="row justify-content-center pt-5">
        {edgeValues.map((value, index) => (
            <div key={index} className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-4">
                <div className="d-flex flex-column align-items-center edge-card p-4 text-wrap ">
                    <div className="d-flex flex-column align-items-center p-2">
                        {/* <img src={value.image} alt={value.title} width={100} className="mb-2 img-fluid"/> */}
                        <h5>{value.title}</h5>
                    <p >{value.description}</p>
                    </div>
                </div>
            </div>
        ))}
    </div>
</div>


            

            <div className="team-section">
                <h4 className="section-title">OUR TEAM</h4>
                <h2 className="section-subtitle">Experts Minds Behind the Neksogix</h2>
                <div className="team-members">
                    {teamMembers.map(member => (
                        <div key={member.id} className="team-member">
                            <div className="team-member-image">
                                <img src={member.imgSrc} alt={member.name} />
                            </div>
                            <div className="team-member-info">
                                <h4 className="team-member-name">{member.name}</h4>
                                <h4 className="team-member-title">{member.title}</h4>
                                <p className="team-member-position">{member.position}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ScheduleFooter/>
        </>
    );
};

export default Company;
