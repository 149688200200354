import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaPinterest, FaLinkedin, FaEnvelope } from "react-icons/fa";

function BlogDetails() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/blogs/${id}`)
      .then(response => response.json())
      .then(data => setBlog(data))
      .catch(error => console.error('Error fetching blog:', error));
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  // Dynamically set the share URL to the current page URL
  const currentURL = window.location.href;

  return (
    <div className="container">
      <h1 style={{ marginTop: '30px', paddingBottom: '20px', fontSize: '50px' }}>{blog.title}</h1>
      <h4>By {blog.author} on {new Date(blog.published_date).toLocaleDateString()}</h4>

      <img src={blog.image_url} alt="Blog Cover" />

      <div style={{ marginTop: '30px', paddingBottom: '20px' }}>
        <p style={{ fontSize: '20px' ,color: '#031D44', fontWeight: 'bold' }}>Sharing is Caring!</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#3b5998', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaFacebook style={{ marginRight: '5px' }} />
              Share
            </button>
          </a>

          {/* <a href={`https://twitter.com/intent/tweet?url=${currentURL}&text=Check this out!`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#1DA1F2', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaTwitter style={{ marginRight: '5px' }} />
              Tweet
            </button>
          </a> */}

          {/* <a href={`https://pinterest.com/pin/create/button/?url=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#bd081c', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaPinterest style={{ marginRight: '5px' }} />
              Pin
            </button>
          </a> */}

          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#0077b5', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaLinkedin style={{ marginRight: '5px' }} />
              LinkedIn
            </button>
          </a>

          <a href={`mailto:?subject=Check this out&body=Check out this link: ${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaEnvelope style={{ marginRight: '5px' }} />
              Email
            </button>
          </a>
        </div>
      </div>

      <div dangerouslySetInnerHTML={{ __html: blog.content }} />

      <div style={{ border: '2px solid #ddd', padding: '20px', marginTop: '20px' }}>
        <img src="/assets/AwaisNaeem.png" alt="Awais Naeem"
          style={{ float: 'left', marginRight: '20px', borderRadius: '50%', width: '120px',marginTop:20 }} />
        <p style={{ fontSize: '18px' }}>
          <strong>Awais Naeem</strong><br />
          He is the owner and founder of <a href="https://embeddedrobotics.com" style={{ color: '#00A79D', fontWeight: 'bold' }}>Embedded Robotics</a>
          and a health-based start-up called <a href="https://nemaloss.com" style={{ color: '#00A79D', fontWeight: 'bold' }}>Nema Loss</a>.
          He is currently the <strong style={{ color: '#00A79D', fontWeight: 'bold' }}>CEO of NEKSOGIX</strong>, leading innovative solutions in the technology sector.
          Awais is very enthusiastic and passionate about Business Development, Fitness, and Technology. Read more about his struggles, and how he went from being called a
          Weak Electrical Engineer to <a href="https://www.embedded-robotics.com/awais-naeem/" style={{ color: '#00A79D', fontWeight: 'bold' }}>Founder of Embedded Robotics</a>.
        </p>
      </div>


      <div style={{ marginTop: '30px', paddingBottom: '20px' }}>
        <p style={{ fontSize: '20px' ,color: '#031D44', fontWeight: 'bold' }}>Sharing is Caring!</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#3b5998', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaFacebook style={{ marginRight: '5px' }} />
              Share
            </button>
          </a>

          {/* <a href={`https://twitter.com/intent/tweet?url=${currentURL}&text=Check this out!`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#1DA1F2', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaTwitter style={{ marginRight: '5px' }} />
              Tweet
            </button>
          </a> */}

          {/* <a href={`https://pinterest.com/pin/create/button/?url=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#bd081c', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaPinterest style={{ marginRight: '5px' }} />
              Pin
            </button>
          </a> */}

          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#0077b5', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaLinkedin style={{ marginRight: '5px' }} />
              LinkedIn
            </button>
          </a>

          <a href={`mailto:?subject=Check this out&body=Check out this link: ${currentURL}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
              <FaEnvelope style={{ marginRight: '5px' }} />
              Email
            </button>
          </a>
        </div>
      </div>


    </div>
  );
}

export default BlogDetails;
