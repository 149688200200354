import React, { useEffect } from "react";
import "../Blog/Blog.css";
import blogpng from "../assets/Animation1.gif";
import node from "../assets/nodejs-2.svg";
import python1 from "../assets/python-5.svg";
import docker from "../assets/docker-4.svg";
import mysql from "../assets/mysql.svg";
import java from "../assets/java.svg";
import react from "../assets/react.svg";
import mongodb from "../assets/mongodb.svg";
import next from "../assets/next-js.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Blog() {


  return (
    <>
      <div className="container-fluid blog">
        <div className="row pt-5 pb-5 d-flex flex-row align-items-center">
          <div className="col-12 col-lg-6 p-5 pt-5 blog-content d-flex flex-column justify-content-center" >
            <h1 className="heading">
              <span>Neksogix: </span> Where Innovation Meets Excellence in Software Development
            </h1>
            <p className="intro-para  pe-5">
            Our mission is to provide innovative software solutions that foster growth, efficiency, and scalability, helping businesses of all sizes navigate the digital landscape with confidence.</p>
            <Link to={'/company'}> <button className="about-btn"><span>More About Us</span></button></Link>
          </div>
          <div className="col-12 col-lg-6 p-5  blog-img" style={{width:620, marginTop: -10}}>
            <img src={blogpng} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="pt-4 pb-4 swiper-bg">
        <Swiper
          spaceBetween={20} // Adjusted to balance spacing on smaller screens
          centeredSlides={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10, // Smaller space between slides on very small screens
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={node} alt="Slide 1" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={python1} alt="Slide 2" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mysql} alt="Slide 3" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={docker} alt="Slide 4" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={java} alt="Slide 5" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={react} alt="Slide 6" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={mongodb} alt="Slide 7" className="swiper-image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={next} alt="Slide 8" className="swiper-image" />
          </SwiperSlide>
        </Swiper>

      </div>
    </>
  );
}

export default Blog;
