import React, { useState, useEffect } from 'react';
import "../ScrollToTop/Scroll.css"
import { FaArrowUp } from 'react-icons/fa';
const Scroll = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to a certain distance
  const toggleVisibility = () => {
    if (window.pageYOffset >1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="scroll-to-top" >
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-button">
          <FaArrowUp></FaArrowUp>
        </button>
      )}
    </div>
  );
};

export default Scroll;
