import { Route, Routes } from 'react-router-dom';
import Navbar from "./Navbar/Navbar"
import Home from './Home/Home'
import Footer from "./Footer/Footer"
import BlogMain from "./BlogMain//BlogMain"
import Scroll from "./ScrollToTop/Scroll"
import Company from "./Company//Company";
import SearchResults from './SearchResults';
import BlogDetails from './BlogTemplate/BlogDetails';
import ServiceDetails from './ServiceTemplate/ServiceDetails';
import Signup from './SignUp/SignUp';
import Login from './Login/Login';
import AdminPage from './Admin/admin';
import Contact from './Contact/Contact';
import { useLocation } from 'react-router-dom';
import CallPage from './CallPage/callPage';
import ProjectMain from './ProjectMain/ProjectMain'
import ProjectTemplate from './ProjectTemplate/ProjectTemplate';
import ScrollToTop from "./ScrollToTop/scrollTop"; 

function App() {
  const location = useLocation();
  return (
    <>
      <Navbar/>
      <ScrollToTop />
      <Routes>
        <Route index element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/blog" element={<BlogMain/>} />
        <Route path="/project" element={<ProjectMain/>} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/login" element={<Login />} /> 
        <Route path="/signup" element={<Signup />} />
        <Route path="/company" element={<Company/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/book-a-call" element={<CallPage/>} />
        <Route path="/blog/:id" element={<BlogDetails/>} />
        <Route path="/search/:query" element={<SearchResults />} />
        <Route path="/project/:name" element={<ProjectTemplate />}/>
        <Route path="/services/:serviceName" element={<ServiceDetails />} />
        </Routes>
      <Scroll />
      {location.pathname !== '/login' && location.pathname !== '/signup' && <Footer/>}
</>
  );
}

export default App;
