import React, { useState, useEffect } from "react";
import "../Testimonials/Testimonials.css";
import "../fonts.css";
import { FaUserCircle, FaQuoteLeft, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const Testimonials = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current starting index for large screens

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const testimonials = [
        {
            quote:
                "They made a highly complex job look very easy. Delivered the work on time and with excellent communication. Very happy with the work. Will certainly consider Neksogix again for similar projects.",
            name: "Thomas Minarik",
            role: "Operations Manager",
            location: "Austria, Europe",
        },
        {
            quote:
                "Neksogix delivered the project perfectly and on time. Their communication and availability were exceptional throughout. The work was flawless, and I will definitely work with them again in the future.",
            name: "Marouf Ahmed",
            role: "Data Scientist",
            location: "Lahore, PK",
        },
        {
            quote:
                "Neksogix's Team delivered flawless data mining and web scraping solutions for us. Their expertise and attention to detail saved us a lot of time and effort. Their timely delivery were remarkable, and I highly recommend them.",
            name: "Mark Peterson",
            role: "Data Analyst",
            location: "New York, USA",
        },
        {
            quote:
                "The AI solution delivered by Neksogix was excellent and very professional. The process was smooth, and they communicated well throughout. I am happy with the results and would recommend them.",
            name: "Sophia Lane",
            role: "AI Consultant",
            location: "London, UK",
        },
        {
            quote:
                "Neksogix team provided outstanding backend development services for my e-commerce store. They delivered project on time and exceeded my expectations. I am very happy with the outcome and their work.",
            name: "Jason Turner",
            role: "E-commerce Manager",
            location: "Toronto, Canada",
        },
        {
            quote:
                "Neksogix’s data analysis service was insightful and accurate. Their professionalism and communication stood out. I am very pleased with the results and would certainly hire them again.",
            name: "Lara Simmons",
            role: "Marketing Specialist",
            location: "Sydney, Australia",
        },
    ];

    // Handle navigation for large screens
    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 3 : prevIndex - 3
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 3 >= testimonials.length ? 0 : prevIndex + 3
        );
    };

    return (
        <div className="testimonials-section">
            <h2 className="testimonials-title">
                What Our <span className="highlight">Clients</span> Say
            </h2>
            {isSmallScreen ? (
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    navigation
                    modules={[Pagination, Navigation, Autoplay]}
                    className="swiper-container"
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index} className="swiper-slide-testimonial">
                            <div className="testimonial-card">
                                <div className="card-header">
                                    <FaUserCircle className="testimonial-icon" />
                                </div>
                                <div className="quote-icon">
                                    <FaQuoteLeft />
                                </div>
                                <p className="testimonial-quote">“{testimonial.quote}”</p>
                                <div className="testimonial-details">
                                    <h5 className="testimonial-name">{testimonial.name}</h5>
                                    <p className="testimonial-role">{testimonial.role}</p>
                                    <p className="testimonial-location">{testimonial.location}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <>
                    <div className="navigation-buttons">
                        <button className="arrow-button" onClick={handlePrev}>
                            <FaArrowLeft />
                        </button>
                        <button className="arrow-button" onClick={handleNext}>
                            <FaArrowRight />
                        </button>
                    </div>
                    <div className="testimonials-cards-container">
                        {/* Display 3 testimonials starting from currentIndex */}
                        {testimonials
                            .slice(currentIndex, currentIndex + 3)
                            .map((testimonial, index) => (
                                <div key={index} className="testimonial-card">
                                    <div className="card-header">
                                        <FaUserCircle className="testimonial-icon" />
                                    </div>
                                    <div className="quote-icon">
                                        <FaQuoteLeft />
                                    </div>
                                    <p className="testimonial-quote">“{testimonial.quote}”</p>
                                    <div className="testimonial-details">
                                        <h5 className="testimonial-name">{testimonial.name}</h5>
                                        <p className="testimonial-role">{testimonial.role}</p>
                                        <p className="testimonial-location">{testimonial.location}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Testimonials;