import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import blogpng from "../assets/MainBlog.gif"; // Example for main blog image
import { BsCalendar2DateFill } from "react-icons/bs";
import PaginationNumber from "../BlogMain/Pagination/Pagination";
import "../ProjectMain/ProjectMain.css";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaBrain, FaLaptopCode, FaShoppingCart, FaChartLine, FaDesktop, FaCube, FaCog, FaTable } from 'react-icons/fa';
import ScheduleFooter from "../ScheduleFooter/ScheduleFooter";

function ProjectMain() {
    const [projects, setprojects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 6;
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState(location.state?.selectedCategory || "All");

    // const [selectedCategory, setSelectedCategory] = useState("All");

    useEffect(() => {
        AOS.init({ duration: 1000 });
        fetchprojects();
    }, []);

    useEffect(() => {
        console.log("Selected Category:", selectedCategory);
        console.log("Projects:", projects);
    }, [selectedCategory, projects]);


    useEffect(() => {
        if (location.state?.selectedCategory) {
            setSelectedCategory(location.state.selectedCategory);
        }
    }, [location.state]);

    const fetchprojects = async () => {
        console.log("url", process.env.REACT_APP_API_URL);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setprojects(data);
            } else {
                console.error("Data fetched is not an array:", data);
            }
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        if (location.pathname.includes("/project")) {
            // If on the project page, scroll to the Featured Projects section
            if (featuredProjectsRef.current) {
                featuredProjectsRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        } else {
            // If on the category page, scroll to the top
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const categories = [
        { name: "All", icon: <FaCube /> },
        { name: "Artificial Intelligence", icon: <FaBrain /> },
        { name: "Machine Learning", icon: <FaBrain /> },
        { name: "Custom Chatbot", icon: <FaCog /> },
        { name: "Web Application", icon: <FaLaptopCode /> },
        { name: "Data Analysis", icon: <FaChartLine /> },
        { name: "Desktop Application", icon: <FaDesktop /> },
        { name: "E-Commerce", icon: <FaShoppingCart /> },
        { name: "Web Scraping", icon: <FaCog /> },
        { name: "Data Visualization", icon: <FaTable /> },
        { name: "Web Development", icon: <FaLaptopCode /> }
    ];

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1);
    };

    const filteredProjects = selectedCategory === "All"
        ? projects
        : projects.filter(project =>
            project.tag.split(", ").some(tag => tag.trim().toLowerCase() === selectedCategory.toLowerCase())
        );
        const featuredProjectsRef = useRef(null);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = filteredProjects.slice(indexOfFirstCard, indexOfLastCard);
    const pageCount = Math.ceil(filteredProjects.length / cardsPerPage);
    const swiperRef = useRef(null);

    // Handle Next and Previous button clicks
    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext(); // Move to the next slide
        }
    };

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev(); // Move to the previous slide
        }
    };
    return (
        <>
            <div className="container-fluid bg-light">
                <div className="row pt-5 pb-5">
                    <div className="col-lg-6 col-12 blogmain-content">
                        <h1  style={{ fontSize: 10 }}><span style={{ color: "#00A79D" }}>Neksogix: </span> Where Vision Transforms into Reality</h1>
                        <Link to="/company"><button className="blogmain-btn mt-4"><span>More About Us</span></button></Link>
                    </div>
                    <div className="col-12 col-lg-6 blogmain-img">
                        <img src={blogpng} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-5 mb-5">
                <div className="text-center" data-aos="fade-down">
                    <h1 ref={featuredProjectsRef} style={{ color: "#00A79D", fontSize: 47, fontWeight: "bold", letterSpacing: "2px" }}>
                        Featured <span style={{ color: "#052f3f", fontWeight: "bold" }}>Projects</span>
                    </h1>
                </div>

                {/* <div className="swiper-button-prev" onClick={handlePrev}>
                    &lt;
                </div> */}
                <Swiper
                    spaceBetween={15}
                    slidesPerView="auto"
                    freeMode={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 3000, // Change slide every 3 seconds
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                        },
                    }}
                >
                    {categories.map((category, index) => (
                        <SwiperSlide key={index} style={{ width: "auto" }} className="category-bar">
                            <div
                                className={`category-item ${selectedCategory === category.name ? "active" : ""}`}
                                onClick={() => handleCategoryChange(category.name)}

                            >
                                <span className="category-icon">{category.icon}</span>
                                <div className="category-name">{category.name}</div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* <div className="swiper-button-next" onClick={handleNext}>
                 &gt;
                </div> */}
                <div className="row p-5 pb-0">
                    {currentCards.map((project, index) => {
                        let BlogImage;
                        try {
                            if (project.image_url) {
                                BlogImage = require(`${project.image_url}`).default;
                            } else {
                                throw new Error("Image URL is undefined or invalid.");
                            }
                        } catch (error) {
                            console.error("Error loading image:", error.message);
                            BlogImage = require('../assets/BLOG2.png').default;
                        }
                        return (
                            <div key={index} className="col-lg-4 col-md-6 col-12 mb-5" data-aos="fade-up">
                                <div className="p-1 d-flex align-items-center justify-content-center">
                                    <div className="card-blog">
                                        <div className="card-image-container-blog">
                                            <img src={project.image_url} alt="Card Image" className="card-image-blog w-100" />
                                        </div>
                                        <div className="card-content-blog">
                                            <h2 className="card-title-blog">Title: {project.title}</h2>
                                            {/* <h2 className="card-title-blog">SubTitle: {project.subtitle}</h2> */}
                                            <Link to={`/project/${project._id}`} className="card-button-blog">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <PaginationNumber
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                />
            </div>
            <ScheduleFooter />
        </>
    );
}

export default ProjectMain;