import React, { useState } from "react";
import "../Contact/Contact.css";
import contact from "../assets/contactus.svg";
import talk from "../assets/talk.svg";
import axios from 'axios';

function CallPage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        service: '',
        budget: '',
        message: ''
    });
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit-form`, formData);
            console.log('Form submitted successfully:', response.data);
            
            setFormData({ name: '', email: '', company: '', service: '', budget: '', message: '' }); // Reset form
            setMessage('Form submitted successfully!');
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 3000);
        } catch (error) {
            console.error('Failed to submit form:', error);
            setMessage('Failed to submit form');
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 3000);
        }
    };

    return (
        <>
            <div className="bg-light">
                <div className="container-fluid">
                    <div className="row justify-content-center pt-5 pb-5">
                        <div className="col-lg-6 col-md-4 col-12 d-flex justify-content-center pt-5 pb-5 contact-form-img">
                            <img src={contact} alt="Contact Us" width={370} className="img-fluid p-3 pe-0" />
                        </div>
                        <div className="col-lg-6 col-md-8 col-12 justify-content-center">
                            <div className="contact-form-container p-4 ms-3 me-3">
                                <div className="text-center mb-4 mt-lg-4 mt-1">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={talk} alt="Let's Talk Icon" className="me-lg-4 me-2 icon-image" />
                                        <h2 className="custom-font m-0">Let's Talk...</h2>
                                    </div>
                                </div>
                                {showMessage && <div className="alert alert-success text-center">{message}</div>}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <input type="text" name="name" className="form-control custom-input" placeholder="Name" value={formData.name} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" name="email" className="form-control custom-input" placeholder="Email" value={formData.email} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" name="company" className="form-control custom-input" placeholder="Company" value={formData.company} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <select name="service" className="form-select custom-select" value={formData.service} onChange={handleChange} required>
                                            <option value="" disabled>Select a service</option>
                                            <option value="web-development">Web Development</option>
                                            <option value="backend-development">Backend Development</option>
                                            <option value="python-scripting">Python Scripting</option>
                                            <option value="data-analysis">Data Analysis</option>
                                            <option value="data-visualization">Data Visualization</option>
                                            <option value="web-scraping">Web Scraping</option>
                                            <option value="machine-learning">Machine Learning</option>
                                            <option value="artificial-intelligence">Artificial Intelligence</option>
                                            <option value="data-mining">Data Mining</option>
                                            <option value="ecommerce-store">Ecommerce Store</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <input type="number" name="budget" className="form-control custom-input" placeholder="Enter your budget ($)" value={formData.budget} onChange={handleChange} required/>
                                    </div>
                                    <div className="mb-3">
                                        <textarea name="message" className="form-control custom-textarea" rows="5" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn custom-btn w-50 mt-2">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CallPage;
