import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, pageCount, onPageChange }) => {
  const pages = [];

  for (let i = 1; i <= pageCount; i++) {
    pages.push(i);
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pageCount) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <span 
        className={`page-item prev ${currentPage === 1 ? 'disabled' : ''}`} 
        onClick={handlePrevClick}
      >
        Prev
      </span>

      {pages.map(page => (
        <span
          key={page}
          className={`page-item ${page === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </span>
      ))}

      <span 
        className={`page-item next ${currentPage === pageCount ? 'disabled' : ''}`} 
        onClick={handleNextClick}
      >
        Next
      </span>
    </div>
  );
};

export default Pagination;
