// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when the location changes (i.e., route changes)
    window.scrollTo(0, 0);
  }, [location]);

  return null; // This component doesn't render anything itself
};

export default ScrollToTop;
