import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const SearchResults = () => {
    const { query } = useParams();
    const [results, setResults] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Simulate a search result fetch
        const fetchResults = async () => {
            // Here, you would typically make an API call to fetch data based on the query
            const searchQuery = decodeURIComponent(query).toLowerCase();

            // Dummy data for demonstration purposes
            const data = [
                { type: 'blog', id: 1, title: 'How to Use React Router' },
                { type: 'service', name: 'web-development', title: 'Web Development Services' },
                { type: 'company', title: 'About Our Company' },
                { type: 'contact', title: 'Contact Us' },
                {type:'home', title:'Home'}
            ];

            // Filter the data based on the query
            const filteredResults = data.filter(item => item.title.toLowerCase().includes(searchQuery));
            setResults(filteredResults);

            // If there's only one result, redirect to that page automatically
            if (filteredResults.length === 1) {
                const result = filteredResults[0];
                if (result.type === 'blog') {
                    navigate(`/blog/${result.id}`);
                } else if (result.type === 'service') {
                    navigate(`/services/${result.name}`);
                } else if (result.type === 'company') {
                    navigate('/company');
                } else if (result.type === 'contact') {
                    navigate('/contact');
                }
                else if (result.type === 'home') {
                    navigate('/home');
                }
            }
        };

        fetchResults();
    }, [query, navigate]);

    return (
        <div>
            {results.length === 0 ? (
                <p>No results found.</p>
            ) : (
                <ul>
                    {results.map((result, index) => (
                        <li key={index}>
                            {result.type === 'blog' && <a href={`/blog/${result.id}`}>{result.title}</a>}
                            {result.type === 'service' && <a href={`/services/${result.name}`}>{result.title}</a>}
                            {result.type === 'company' && <a href="/company">{result.title}</a>}
                            {result.type === 'contact' && <a href="/contact">{result.title}</a>}
                            {result.type === 'home' && <a href="/home">{result.title}</a>}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchResults;
