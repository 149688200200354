import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import blogpng from "../assets/MainBlog.gif"; // Example for main blog image
import { BsCalendar2DateFill } from "react-icons/bs";
import PaginationNumber from "./Pagination/Pagination";
import "../BlogMain/BlogMain.css";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ScheduleFooter from "../ScheduleFooter/ScheduleFooter";
import { FaProjectDiagram, FaChartBar, FaRobot, FaCode,FaChartLine , FaDatabase, FaTools, FaBrain, FaDesktop, FaCube, FaMicrochip } from 'react-icons/fa';
import { BsCpu,  BsGraphBar, BsBoxArrowInDown } from 'react-icons/bs';

function BlogMain() {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 6;

    useEffect(() => {
        AOS.init({ duration: 1000 });
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blogs`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setBlogs(data);
            } else {
                console.error("Data fetched is not an array:", data);
            }
        } catch (error) {
            console.error("Error fetching blogs:", error);
        }
    };
    const featuredProjectsRef = useRef(null);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = blogs.slice(indexOfFirstCard, indexOfLastCard);
    const totalCards = blogs.length;
    const pageCount = Math.ceil(totalCards / cardsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (featuredProjectsRef.current) {
            featuredProjectsRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start", // Scroll to the top of the element
            });
        }
    };

    const categories = [
        { name: "All", icon: <FaProjectDiagram /> },
        { name: "Binary Classification", icon: <FaChartBar /> },
        { name: "Machine Learning", icon: <FaBrain /> },
        { name: "Data Analysis", icon: <FaChartLine  /> },
        { name: "Data Science", icon: <FaDatabase /> },
        { name: "Regression Analysis", icon: <FaChartBar /> },
        { name: "Predictive Modeling", icon: <FaTools /> },
        { name: "Deep Learning", icon: <FaBrain /> },
        { name: "Microcontroller", icon: <BsCpu /> },
        { name: "Robotics", icon: <FaRobot /> },
        { name: "IOT", icon: <FaCube /> },
        { name: "Internet of Things", icon: <BsBoxArrowInDown /> },
        { name: "Multiclass Classification", icon: <FaBrain /> },
        { name: "ESP8266", icon: <FaMicrochip /> },
    ];

    const [selectedCategory, setSelectedCategory] = useState("All");

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1);
    };

    const filteredBlogs = selectedCategory === "All"
        ? blogs
        : blogs.filter(blog =>
            Array.isArray(blog.categories) &&
            blog.categories.some(cat => cat.trim() === selectedCategory)
        );

    return (
        <>
            <div className="container-fluid bg-light">
                <div className="row pt-5 pb-5">
                    <div className="col-lg-6 col-12 blogmain-content">
                        <h1 ><span style={{ color: "#00A79D" }}>Neksogix's</span> Insights & Trends</h1>
                        <button className="blogmain-btn mt-4"><span>How it works</span></button>
                    </div>
                    <div className="col-12 col-lg-6 blogmain-img">
                        <img src={blogpng} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-5 mb-5">
                <div className="text-center" data-aos="fade-down">
                    <h1 ref={featuredProjectsRef} style={{ color: "#00A79D", fontSize: 47, fontWeight: "bold", letterSpacing: "2px" }}>Featured <span style={{ color: "#052f3f" }}>Blogs</span></h1>
                </div>

                <Swiper
                    spaceBetween={10}
                    slidesPerView="auto"
                    freeMode={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 3000, // Change slide every 3 seconds
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                        },
                    }}
                >
                    {categories.map((category, index) => (
                        <SwiperSlide key={index} style={{ width: "auto" }} className="category-bar">
                            <div
                                className={`category-item ${selectedCategory === category.name ? "active" : ""}`}
                                onClick={() => handleCategoryChange(category.name)}
                            >
                                <span className="category-icon">{category.icon}</span>
                                {category.name}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="row p-5 pb-0">
                    {filteredBlogs.slice(indexOfFirstCard, indexOfLastCard).map((blog, index) => {
                        let BlogImage;
                        try {
                            // Assuming `blog.image_url` contains a relative path like "../assets/BLOG6.png"
                            if (blog.image_url) {
                                BlogImage = require(`${blog.image_url}`).default;
                            } else {
                                throw new Error("Image URL is undefined or invalid.");
                            }
                        } catch (error) {
                            console.error("Error loading image:", error.message);
                            BlogImage = require('../assets/BLOG2.png').default; // Use a fallback image if the original fails
                        }
                        console.log(blog.image_url);
                        console.log(BlogImage);
                        return (
                            <div key={index} className="col-lg-4 col-md-6 col-12 mb-5" data-aos="fade-up">
                                <div className="p-1 d-flex align-items-center justify-content-center">
                                    <div className="card-blog">
                                        <div className="card-image-container-blog">
                                            <img src={blog.image_url} alt="Card Image" className="card-image-blog w-100" />
                                        </div>
                                        <div className="card-content-blog">
                                            <div className="card-date">
                                                <i><BsCalendar2DateFill /></i> <span>{new Date(blog.published_date).toLocaleDateString()}</span>
                                            </div>
                                            <h2 className="card-title-blog">{blog.title}</h2>
                                            <Link to={`/blog/${blog._id}`} className="card-button-blog">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <PaginationNumber
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                />
            </div>
            <ScheduleFooter />
        </>
    );
}

export default BlogMain;
