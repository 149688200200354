import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../fonts.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Project/Project.css";

function Project() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);


    // Example data array
    const projectItems = [
        {
            title: "AI Naming Tool Generator",
            description: "A sophisticated AI-driven tool that automates the generation of business names based on user-defined keywords and genres, enhancing productivity and creativity.",
            link: "/project"
        },
        {
            title: "Customized Chatbot Solution",
            description: "Our NLP expertise powers our Customized Chatbot Solution for educational support. Enhance student engagement and administrative efficiency with our advanced chatbot.",
            link: "/project"
        },
        {
            title: "Blessed By PBA",
            description: "A robust web platform designed to connect property buyers with industry professionals like mortgage brokers and real estate agents, enhancing user engagement and streamlining property transactions.",
            link: "/project"
        }
    ];

    return (
        <>
            <div className="container-fluid bg-light project-container pt-5" >
                <div
                    className="background-image-container"
                    data-aos="slide-right"
                ></div>
                <div className="row p-md-5  d-lg-flex align-items-lg-center">
                    <div className="col-lg-6 col-12 pb-5 p-lg-5" data-aos="fade-up">
                        <h2 className="project-text text-wrap " style={{color: "white"}}>
                            Discover our innovative projects that showcase our expertise in cutting-edge technology solutions.
                        </h2>
                        <div className="project-more pt-3">
                            <Link className="project-more project-btn" to={"/project"} style={{paddingBottom:7}} >
                                Explore Our Projects
                                <FaArrowRight className="arrow-icon-project" />
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12 p-lg-5">
                        <ul className="ps-0">
                            {projectItems.map((item, index) => (
                                <li
                                    key={index}
                                    style={{ listStyle: "none" }}
                                    className="pb-4"
                                    data-aos="fade-up"
                                    data-aos-delay={index * 100}
                                >
                                    <div className="list-container">
                                        <h4 className="pt-3" style={{color: '#f3f8f2'}}>{item.title}</h4>
                                        <p style={{color: '#f3f8f2'}}>{item.description}</p>
                                        <div className="project-more-container">
                                            <a className="learn-project-more learn-project-btn" href={item.link} style={{paddingBottom:7}}>
                                                Learn More
                                                <FaArrowRight className="arrow-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Project;
