import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../SignUp/SignUp.css'; // Import the CSS file
import ScheduleFooter from '../ScheduleFooter/ScheduleFooter';

const Signup = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
    
        if (!email.includes('@neksogix') || !email.split('@')[0].includes('_')) {
            setError('Not Authorized: Please use a valid Neksogix email format.');
            return;
        }
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, {
                firstname,
                lastname,
                email,
                password,
            });
            if (response.status === 201) {
                alert('User created successfully');
                navigate('/login'); // Redirect to login page after signup
            }
        } catch (err) {
            setError('Signup failed. Please try again.');
        }
    };
    

    return (
        <>
        <div className='container-fluid mb-0 signup-outer pt-5 pb-5'>
        <div className="signup-container ">
        <form className="signup-form" onSubmit={handleSignup}>
            <h2>Sign Up </h2>
            <p >Register Yourself Now!</p>
            {error && <p className="error-message">{error}</p>}
            <input
                type="text"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
            />
            <input
                type="text"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">Sign Up</button>
        </form>
    </div>
    </div>
    </>
    );
};

export default Signup;
