import React, { useState, useEffect } from "react";
import "../Contact/Contact.css";
import blog from "../assets/newcontact.svg";
import faq from "../assets/faq.svg";
import respond from "../assets/response.svg";
import nda from "../assets/NDA.svg";
import expert from "../assets/techexperts.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Contact() {
  const navigate = useNavigate();
  const faqs = [
    { question: "How do you handle project changes or additional requirements?", answer: "We understand that business needs can evolve during a project. If any changes or additional requirements exist, we assess the impact on the timeline and budget and work collaboratively with you to accommodate the necessary adjustments." },
    { question: "What is the cost of your services?", answer: "The cost of our services varies depending on the scope and complexity of the project. After discussing your requirements, we provide a detailed proposal with a transparent breakdown of costs. Our goal is to offer competitive pricing while maintaining the highest quality standards." },
    { question: "What services do you offer?", answer: "We offer a wide range of services, including web development, web design, backend development, data analysis, Web Scraping,Python Scripting, and more. Our goal is to provide comprehensive solutions that cater to your business needs." },
    { question: "What is the typical project timeline and process?", answer: "Project timelines and processes can vary depending on the complexity and scope of the project. After understanding your requirements, we provide a detailed project plan with milestones and estimated timelines. Regular communication and updates ensure you know the progress throughout the development process." },
    { question: "Can you integrate your software solutions with existing systems or third-party platforms?", answer: "Yes, we have extensive experience in integrating our software solutions with various systems and platforms. We will analyze your existing infrastructure and develop seamless integrations to ensure smooth operation and data exchange." },
    { question: "What level of support do you provide after the completion of the project?", answer: "We offer ongoing support and maintenance services to ensure the smooth functioning of your software solution. Our support team is available to address any issues, provide updates, and assist with future enhancements." },
    { question: "How do you manage communication with clients?", answer: "Engage a skilled group of developers via Neksogix, where our resources can readily adapt to the client's preferred time zone. We leverage contemporary communication platforms such as Slack, Jira, Trello, Skype, Zoom, Asana and more." },
    { question: "What technologies and programming languages do you specialize in?", answer: "We provide comprehensive software solutions for a variety of platforms including web, mobile (iOS and Android), and other custom solutions. Our team of experts has extensive experience working with a wide range of technologies and programming languages, including but not limited to Java, Python, JavaScript, Ruby on Rails, React, and Node Js." },
    { question: "How do you handle changes or updates during the development process?", answer: "We understand that requirements can evolve during the development process. We follow an agile approach that allows for flexibility and accommodates changes. We collaborate closely with clients to handle change requests efficiently." },
    { question: "How can I get in touch with your team for further inquiries or to discuss my project?", answer: "You can reach out to us through the contact form on our website, email, or phone. Our team is responsive and will be happy to assist you with any inquiries or discuss your project in detail." },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    topic: '',
    name: '',
    email: '',
    phone: '',
    requirements: '',
    preferredTime: '',
    file: null,
    timeZone: 'UTC',
    formType: 'quote',
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleOpenModal = (formType) => {
    setFormData({ ...formData, formType });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    console.log('Closing modal and resetting form data');
    setIsModalOpen(false);
    setFormData({
      topic: '',
      name: '',
      email: '',
      phone: '',
      requirements: '',
      preferredTime: '',
      timeZone: 'UTC',
      file: null,
      formType: 'quote',
    });
    setErrors({});
    setStatusMessage('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      file: e.target.files[0]
    }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      newErrors.email = "Invalid email address.";
      valid = false;
    }

    if (formData.file && !['application/pdf', 'image/jpeg', 'image/png'].includes(formData.file.type)) {
      newErrors.file = "File type must be PDF, JPG, or PNG.";
      valid = false;
    } else if (formData.file && formData.file.size > 5242880) {
      newErrors.file = "File size must be under 5MB.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formPayload = new FormData();
    Object.keys(formData).forEach(key => {
      formPayload.append(key, formData[key]);
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit-contact-form`, formPayload, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log('API response:', response.data);
      console.log('msg', response.data.message);
      console.log('status', response.data.success);
      if (typeof response.data === 'string' && response.data.includes('successfully')) {
        setMessageType("success");
        setStatusMessage("Form submitted successfully!");
        setTimeout(() => {
          handleCloseModal();
          console.log("Form cleared");
          setStatusMessage('');
        }, 3000); // Reset and close modal after 3 seconds
      } else {
        setMessageType("error");
        setStatusMessage(response.data.message);
      }
    } catch (error) {
      setMessageType("error");
      setStatusMessage("Error submitting form.");
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isModalOpen]);

  const handleBookCall = () => {
    navigate("/book-a-call"); // This will navigate to the "Book A Call" page
  };

  const timeZones = [
    'UTC',
    'America/New_York',
    'America/Los_Angeles',
    'Europe/London',
    'Asia/Kolkata',
    'Australia/Sydney',
  ];

  const messageStyle = messageType === "success" ? { color: "green" } : { color: "red" };

  return (
    <>
      {/* Header Section */}
      <div className="bg-light">
        <div className="container-fluid">
          <div className="row pt-5 pb-3">
            <div className="col-lg-6 d-flex flex-column justify-content-center service-header p-3 ps-5">
              <h2 className="custom-font">Your Success With <span className="neksogix">Neksogix</span></h2>
              <Link to="/company">
                <button className="collaborate-btn">
                  <span>How It Works</span>
                </button>
              </Link>
            </div>
            <div className="col-lg-6 d-flex justify-content-center pt-5">
              <img src={blog} alt="" width={300} className="img-fluid p-3 pe-0" />
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="heading-streamline text-center p-5">
          <h1 className="custom-font mt-3">Ready to Transform Your Vision into Reality?</h1>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-3 col-md-4 col-6 d-flex flex-column align-items-center">
              <img src={respond} alt="Respond Icon" width={50} />
              <h6 className="text-center mt-3">We will respond to you within <span className="fw-bolder" style={{ color: "#052f3f" }}>24 hours.</span></h6>
            </div>
            <div className="col-lg-3 col-md-4 col-6 d-flex flex-column align-items-center">
              <img src={nda} alt="NDA Icon" width={50} />
              <h6 className="text-center mt-3">We'll sign an <span className="fw-bolder" style={{ color: "#052f3f" }}>NDA</span></h6>
            </div>
            <div className="col-lg-3 col-md-4 col-6 d-flex flex-column align-items-center">
              <img src={expert} alt="Experts Icon" width={50} />
              <h6 className="text-center mt-3">You'll be talking to product and tech <span className="fw-bolder" style={{ color: "#052f3f" }}>experts.</span></h6>
            </div>
          </div>

          <div className="container text-center mb-5 ">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <button className="btn custom-btn w-50 mt-2" onClick={() => handleOpenModal("quote")}>Get a Quote</button>
              </div>
              <div className="col-lg-6 mb-3">
                <button className="btn custom-btn w-50 mt-2" onClick={() => handleOpenModal("demo")} >Get Demo</button>
              </div>
            </div>
          </div>
          <div className="container text-center">
            <h3 className="text-center">Get an industry <span className="fw-bolder" style={{ color: "#052f3f" }}>Expert</span> on call</h3>
            <button className="btn custom-btn mt-2" style={{ width: 140 }} onClick={handleBookCall}>Book A Call</button>
          </div>

          <div className="container info-mail-container mt-5 mb-2">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="info-block d-flex flex-column align-items-center mb-3">
                <h4 className="text-white">Mail Address</h4>
                <p className="text-white pt-0 pb-0">neksogix_solutions@neksogix.com</p>
              </div>
              <div className="info-block d-flex flex-column align-items-center mb-3">
                <h4 className="text-white">Address</h4>
                <p className="text-white pt-0 pb-0">Austin, Texas, USA.</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* FAQs Section */}
        <div className="container-fluid d-flex flex-column align-items-center pt-5">
          <div className="heading-streamline d-flex flex-column align-items-center justify-content-center p-2 pt-5">
            <div className="d-flex align-items-center justify-content-center">
              <img src={faq} alt="FAQ Icon" width={50} className="me-2" />
              <h2 className="custom-font m-0">FAQs</h2>
            </div>
            <h3 className="custom-font mt-1">Frequently Asked Questions</h3>
          </div>

          <div className="accordion-container w-100 ps-lg-5 pe-lg-5 py-5">
            <div className="accordion" id="faqAccordion">
              {faqs.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button className="accordion-button collapsed custom-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                      {faq.question}
                    </button>
                  </h2>
                  <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#faqAccordion">
                    <div className="accordion-body custom-accordion-body">
                      <p style={{ fontWeight: 500 }}>{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <button className="cls-btn" onClick={handleCloseModal}>X</button>
              <h2>{formData.formType === "quote" ? "Request a Quote" : "Request a Demo"}</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="topic">Topic Title</label>
                  <input
                    type="text"
                    id="topic"
                    name="topic"
                    value={formData.topic}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone (Optional)</label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="requirements">Write Your Message</label>
                  <input
                    id="requirements"
                    name="requirements"
                    value={formData.requirements}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="preferredTime">Preferred Time for Meeting</label>
                  <input
                    type="datetime-local"
                    id="preferredTime"
                    name="preferredTime"
                    value={formData.preferredTime}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="timeZone">Time Zone</label>
                  <select
                    id="timeZone"
                    name="timeZone"
                    value={formData.timeZone}
                    onChange={handleChange}
                    required
                  >
                    {timeZones.map((zone) => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="file">Attach File (PDF, JPG, PNG)</label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileChange}
                  />
                  {errors.file && <p className="error">{errors.file}</p>}
                </div>
                <div className="form-group">
                  {statusMessage && (
                    <div className={`alert alert-${messageType === "success" ? "success" : "danger"}`}>{statusMessage}</div>
                  )}
                  <button type="submit" className="btn custom-btn w-100 mt-2">Submit</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Contact;
