import Blog from "../Blog/Blog"
import Services from "../Services/Services"
import Insights from "../Insights/Insights"
import Project from "../Project/Project"
import ScheduleFooter from "../ScheduleFooter/ScheduleFooter"
import Testimonials from "../Testimonials/Testimonials"
import React from "react"
function Home() {
    return(
        <>
        <div><Blog></Blog></div>
        <div><Services></Services></div>
        <div><Insights></Insights></div>
        <div><Project></Project></div>
        <div><Testimonials></Testimonials></div>
        <ScheduleFooter/>
        </>
    )
}
export default Home