import React, { useState, useRef } from "react";
import myImage from "../assets/Schedule.jpg";
import { Link } from "react-router-dom"
import '../fonts.css';
import emailjs from "@emailjs/browser";
import "../Footer/Footer.css";


function ScheduleFooter() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState(""); // New state for the status message
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Send data to Web3Forms API to send email
      const web3FormData = new FormData();
      web3FormData.append("name", formData.name);
      web3FormData.append("email", formData.email);
      web3FormData.append("message", formData.message);
      web3FormData.append("access_key", "0eafc94d-6fa6-4e26-84dd-e96d5633ea41");

      const web3Response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: web3FormData,
      });

      const web3Data = await web3Response.json();

      if (web3Data.success) {
        setStatusMessage("Email sent successfully!");

        // Step 2: Save data to MongoDB database
        const dbResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: formData.message,
          }),
        });

        if (dbResponse.ok) {
          console.log('Form data saved to DB');
        } else {
          console.error('Failed to save data to DB');
          setStatusMessage("Failed to save data. Please try again.");
        }

        // Reset the form
        setFormData({ name: "", email: "", message: "" });
      } else {
        console.error("Web3Forms Error:", web3Data);
        setStatusMessage(web3Data.message || "Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatusMessage("Failed to send email. Please try again.");
    }

    // Clear the status message after 3 seconds
    setTimeout(() => {
      setStatusMessage('');
    }, 3000); // 3000 ms = 3 seconds
  };
  
  return (
    <>

      <div className="container-fluid footer-area ">
        <div className="row pt-5 pb-5 d-flex align-items-center">
          <div className="col-lg-8 col-12 p-5 pt-3">
            <div className="d-flex flex-column align-items-center align-items-lg-start footer-content p-sm-2 text-center text-lg-start">
              <h1>Schedule a free consultation with one of our experts.</h1>
              <h5>
                Take the first step towards a brighter future and supercharge
                your business with cutting-edge technologies, driving unparalleled success and
                growth, ensuring your competitive edge in the market.
              </h5>
              <Link to={"/book-a-call"}> <button className="footer-btn">
                <span>Schedule Now</span>
              </button> </Link>
            </div>
          </div>
          <div className="col-lg-4 col-12 pt-0 ps-3 pe-3 pb-3  contact-content">
            <div className="contact-form">
              <h1 className="text-center">Contact Us</h1>
              <form ref={form} onSubmit={sendEmail}>
                <div className="m-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="m-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="m-3">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Type Your Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="text-center mt-1">
                  {statusMessage && (
                    <p className="status-message fw-bold" style={{ color: "#00A79D", fontStyle: "italic", fontSize: 19 }}>{statusMessage}</p> // Display the status message
                  )}
                </div>
                <div className="button-container">
                  <button type="submit" className="m-3 submit-btn">
                    <span>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ScheduleFooter