import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaArrowRight } from 'react-icons/fa';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import "../Services/Services.css";
import service from "../assets/AI_img.gif";
import service1 from "../assets/ML_ANimation.gif";
import service2 from "../assets/DA-animation.gif";
import { Link } from 'react-router-dom';

function Services() {
  const [servicesData] = useState([
    {
      title: 'Artificial Intelligence',
      description: "Our expertise in artificial intelligence enables us to deliver bespoke solutions that are precisely tailored to your needs. Our cutting-edge AI solutions are designed to enhance your company's capabilities and optimize its performance in today's highly competitive market.",
      image: service,
      link:"/services/artificial-intelligence"
    },
    {
      title: 'Machine Learning',
      description: "Our machine learning capabilities empower your business with predictive insights and automation, delivering tailored solutions that enhance decision-making and drive innovation in today's data-driven economy, ensuring a competitive edge in an increasingly complex market.",
      image: service1,
      link:"/services/machine-learning"
    },
    {
      title: 'Data Analysis',
      description: "Our proficiency in data analysis empowers us to provide customized solutions that meet your specific requirements, delivering insights that enhance decision-making and optimize performance in today's competitive landscape and optimize its performance in today's highly competitive market.",
      image: service2,
      link:"/services/data-analysis"
    },
  ]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Pagination, Navigation, Autoplay]}
      className="mySwiper service-container"
    >
      {servicesData.map((service, index) => (
        <SwiperSlide key={index} style={{ position: 'relative', minHeight: '600px' }}>
          <div className='row d-flex justify-content-center align-items-center pt-2 pb-2'>
            {index % 2 === 0 ? (
              <>
                <div className='col-12 col-md-7 col-lg-6 p-4 service-content text-center text-lg-start'>
                  <div className='d-flex flex-column justify-content-center services-text align-items-lg-start text-wrap ps-lg-5'>
                    <h1>{service.title}</h1>
                    <p>{service.description}</p>
                    <div className='learn-more-container' style={{paddingTop:30}}>
                      <Link className="learn-more learn-btn" to={service.link} target="_blank" style={{paddingBottom:7}}>
                        Learn More
                        <FaArrowRight className="arrow-icon-btn" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-5 col-lg-6 p-4 d-flex justify-content-center align-items-center'>
                  <img src={service.image} alt="" className='service-img' />
                </div>
              </>
            ) : (
              <>
                <div className='col-12 col-md-5 col-lg-6 p-4 d-flex justify-content-center align-items-center'>
                  <img src={service.image} alt="" className='service-img' />
                </div>
                <div className='col-12 col-md-7 col-lg-6 p-4 service-content text-center text-lg-start'>
                  <div className='d-flex flex-column justify-content-center align-items-lg-start text-wrap ps-lg-5 p-4 p-lg-0'>
                    <h1>{service.title}</h1>
                    <p>{service.description}</p>
                    <div className='learn-more-container' style={{paddingTop:30}}>
                      <Link className="learn-more learn-btn" to={service.link} target="_blank" style={{paddingBottom:7}}>
                        Learn More
                        <FaArrowRight className="arrow-icon-btn" />
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Services;
