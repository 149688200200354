import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import Blog1 from "../assets/Edited Four.jpg";
import Blog2 from "../assets/edited.png";
import Blog4 from "../assets/Edited 3.jpg";
import Blog5 from "../assets/Edited 2.jpg";
import "../Insights/Insights.css";


function Insights() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    window.scrollTo({ top: 0, behavior: "smooth" });

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const InsightsData = [
    {
      title: "A Beginner's guide for Machine Learning Text Classification",
      image: Blog2,
      description: "Learn the basics of text classification in machine learning with Python, featuring simple explanations, practical examples, and hands-on coding exercises for novices.",
      to: "/blog",
    },
    {
      title: "Artificial Intelligence 101: How Does AI Work",
      image: Blog4,
      description: "Explore the foundations of artificial intelligence in this introductory guide, explaining how AI systems function and their impact on various industries and everyday life.",
      to: "/blog",
    },
    {
      title: "How to Forecast Sales using Machine Learning",
      image: Blog5,
      description: "Trained models were evaluated using Root Mean Squared Error, Mean Absolute Error, and R2 Score between the predicted sale values and the actual sale values in the test dataset.",
      to: "/blog",
    },
    {
      title: "Microcontroller Basics - A Comprehensive Guide for Beginners",
      image: Blog1,
      description: "Unlock the essentials of microcontrollers with this beginner-friendly guide, covering fundamental concepts, applications, and step-by-step instructions for getting started.",
      to: "/blog",
    },
  ];

  return (
    <div className="container-fluid insight-section">
      <div className="text-center insight-content" data-aos="fade-up">
        <h1>Featured Blogs</h1>
        <h5>Discover Insights and Inspiration in Our Featured Blogs!</h5>
      </div>
      {isSmallScreen ? (
        <div className="swiper-container-blog">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={2}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,

              slideShadows: false,
            }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination, Autoplay]}
            className="mySwiper "
          >
            {InsightsData.map((insight, index) => (
              <SwiperSlide key={index} className="swiper-slide-blog">
                <div className="card">
                  <div className="image-container">
                    <img
                      src={insight.image}
                      className="card-img-top"
                      alt={insight.title}
                    />
                    <div className="overlay-card">
                      <div className="text p-3">
                        <p>{insight.description}</p>
                        <Link to={insight.to} className="explore-btn exp" style={{paddingBottom:12}}>
                          Explore Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <h6 className="card-title">{insight.title}</h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="row insight-row">
          {InsightsData.map((insight, index) => (
            <div
              key={index}
              className="col-lg-3 custom-col-md-6  d-flex align-items-center justify-content-center mb-3"
              data-aos="zoom-in-up"
            >
              <div className="card">
                <div className="image-container">
                  <img
                    src={insight.image}
                    className="card-img-top"
                    alt={insight.title}
                  />
                  <div className="overlay-card">
                    <div className="text p-3">
                      <p>{insight.description}</p>
                      <Link to={insight.to} className="explore-btn exp" style={{paddingBottom:7}}>
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <h6 className="card-title">{insight.title}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="text-center pb-5" data-aos="fade-up">
        <div className="explore-more-container">
          <Link
            className="explore-more explore-btn"
            to={"/blog"}
            target="_blank"
            style={{paddingBottom:7}}
          >
            Explore More
            <FaArrowRight className="arrow-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Insights;
