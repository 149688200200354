import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import '../Login/Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
                email,
                password,
            });

            if (response.status === 200) {
                // On successful login, redirect to the admin page
                navigate('/admin');
            }
        } catch (err) {
            setError('Invalid credentials. Please try again.');
        }
    };

    return (
        <>
            <div className='container-fluid login-outer pt-5 pb-5'>
                <div className="login-container">
                    <form className="login-form" onSubmit={handleLogin}>
                        <h2 className="login-title">Welcome Back <span>Neksogix</span> Family!</h2>
                        <p className="login-subtitle pt-2">Please log in to your account</p>
                        <div className="form-group login-form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" className="form-control login-control" placeholder="Enter your email" value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </div>
                        <div className="form-group login-form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" className="form-control login-control" placeholder="Enter your password" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required />
                        </div>
                        <button type="submit" className="login-btn mt-3 w-50"><span>Login</span></button>
                        <p className="pt-4 mb-0">
                            Don't have an account ?{' '}
                            <Link to="/signup" className='register-now'>
                                Register Now
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </>






    );
};

export default Login;
